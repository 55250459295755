<template>
    <div>
        <TableList
            :form_data_seo.sync="form_data_seo"
            :columns="columns"
            :get_table_list="this.get_table_list"
            :slot_table_list="[]"
            ref="list"
            :submit_preprocessing="submit_preprocessing"
        >
        </TableList>

    </div>
</template>

<script>

import TableList from "@/components/TableList";
import { loginLog } from "@/api/business";
                                                                                                                                                                                                                             
const columns = [
    {
        title: "姓名",
        dataIndex: "username",
    },
    {
        title: "部门",
        dataIndex: "department",
    },
    {
        title: "岗位",
        dataIndex: "role",
    },
    {
        title: "账号",
        dataIndex: "phone",
    },
    {
        title: "标签",
        dataIndex: "desc",
    },
    
    {
        title: "登录时间",
        dataIndex: "create_time",
    },
];

export default {
    name: "Index",
    components: {
		TableList,
    },
    data() {
        return {
            get_table_list: loginLog,
            submit_preprocessing: {
                array_to_string: ['department']
            },
            order_details: {
                goods: [],
            },
            columns,
            form_data_seo: {
                list: [
					{
                        type: "tree-select",
                        name: "department",
                        title: "部门",
                        options: {},
                        treeData: [],
                        multiple: true
                    },
                    {
                        type: "text",
                        name: "username",
                        title: "姓名",
                        placeholder: '姓名/账号/工号/手机号',
                        mode: "default",
                        options: {},
                        list: []
					},
					{
						type: "select",
						name: "user_status",
						title: "在职状态",
						mode: "default",
						options: {},
						list: this.$config.user_status_list
					},
					{
                        type: "range_date",
                        name: "range_date",
                        title: "登录时间",
                        options: {},
                        start: {
                            name: 'start_time'
                        },
                        end: {
                            name: 'end_time'
                        },
                    },
                ],
                ...this.$config.form_data_seo
            },
            form_data: {}
        };
    },
    async created() {
        this.$method.get_department().then(res => {
			this.form_data_seo.list = this.$method.set_form_list(this.form_data_seo.list,'department','treeData',res)
        });
    },
    methods: {

    }
};
</script>

<style lang="less">

</style>